import React from 'react';
import {MainHomeAbout} from './screenComponent/MainHomeAbout';
import {HomeOutlined, RightOutlined} from '@ant-design/icons';
import {MainFooter} from './screenComponent/MainFooter';
import photo13 from '../../Assets/Images/photo13.jpeg';
import photo16 from '../../Assets/Images/photo16.png';

export const About = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${photo16})`,
          height: '50vh',
          width: 'fit',
          backgroundSize: 'cover',
          backgroundBlendMode: 'hard-light',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        className={'bg-local lg:bg-fixed '}
      >
        <h1 className={'text-6xl text-white font-extrabold py-8 px-4 md:py-16 md:px-32'}>About Us</h1>
        <div
          className={'absolute -mb-36 w-48 h-16 bg-white text-black text-xl flex items-center left-44 bottom-80 lg:left-3/4 lg:bottom-80 border rounded 2xl:bottom-96 md:mb-56 lg:mb-0 md:left-3/4'}>
          <div><HomeOutlined className={'text-xl ml-2'} /> HOME</div>
          <div><RightOutlined className={'text-xl ml-1'} />ABOUT</div>
        </div>
      </div>
      <MainHomeAbout />
      <div className={'bg-cover bg-no-repeat h-94 overflow-hidden opacity-70 md:mt-24 md:mb-8 mb-6 mt-6'}
        style={{backgroundImage:`url(${photo13})`}}
      >
        <div className={'grid grid-cols-1 lg:grid-cols-3 text-black p-6 lg:p-16 gap-4 text-justify'}>
          <div className={'bg-green-100 p-4 lg:p-16'}>
            <h1 className={'text-green-500 font-bold mb-2 text-2xl'}>Our Vision</h1>
            <p className={'text-xl'}>
                Imagine a future where our mats redefine comfort and well-being for every cow,
                enhancing their quality of life while maximizing farm productivity. Our vision
                is to lead the industry in innovative materials and design, We envision a world
                where farmers trust our products to optimize herd health,and reduce environmental
            </p>
          </div>
          <div className={'bg-green-100 p-4 lg:p-16'}>
            <h1 className={'text-green-500 font-bold mb-2 text-2xl'}>Original Purpose</h1>
            <p className={'text-xl'}>
                The original purpose for a company producing mats for cows is typically to provide
                a comfortable and hygienic flooring solution for dairy cows in barns or milking parlors
                These mats help prevent injuries, promote better hoof health, reduce stress on joints,
                and improve overall cow comfort overall farm efficiency.
            </p>
          </div>
          <div className={'bg-green-100 p-4 lg:p-16'}>
            <h1 className={'text-green-500 font-bold mb-2 text-2xl'}>Original Values</h1>
            <p className={'text-xl'}>
                The original value for a company making mats for cows lies in enhancing animal welfare,
                improving farm productivity, and providing durable, reliable solutions for dairy farmers.
                By prioritizing the comfort and well-being of cows, the sustainability of the dairy industry.
            </p>
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};
