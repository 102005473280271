import React from 'react';
import {Footer} from 'antd/es/layout/layout';
import {MailOutlined, PhoneOutlined} from '@ant-design/icons';
import {Divider} from 'antd';

const MainFooter = () => {
  return(
    <>
      <Footer className={'bg-slate-900'}>
        <div className={'ml-0 mr-0 lg:ml-36 lg:mr-36 text-white'}>
          <div className={'text-center lg:flex lg:justify-between'}>
            <div>
              <PhoneOutlined />
              <text className={'pl-3 lg:text-lg'}>Call Us at</text>
            </div>
            <div>
              <MailOutlined />
              <text className={'pl-3 lg:text-lg'}>Email ID</text>
            </div>
          </div>
          <div className={'text-center pt-3 lg:pt-0 lg:flex lg:justify-between'}>
            <text className={'text-md lg:text-xl font-bold'}>9822276721 | 7744001589 </text>
            <text className={'text-lg lg:text-xl font-bold '}>contact@goodfeelcowmats.com</text>
          </div>
          <Divider style={{backgroundColor: 'white'}} />
          <div className={'text-center lg:flex lg:justify-between'}>
            <text>Good Feel Cow Mat | All Rights Reserved.</text>
            <text>Developed by: Quantum Infotech</text>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default MainFooter;
