import React from 'react';
import {Button, Image} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Screenshot1 from '../../../../Assets/Images/Screenshot1.png';
import Screenshot2 from '../../../../Assets/Images/Screenshot2.png';
import Screenshot3 from '../../../../Assets/Images/Screenshot3.png';
import rivew from '../../../../Assets/Images/Rivew.png';

const data = [
  {
    img:Screenshot1,
  },
  {
    img:Screenshot2,
  },
  {
    img:Screenshot3,
  },
  {
    img:Screenshot1,
  },
  {
    img:Screenshot2,
  },
  {
    img:Screenshot3,
  },
];

const MainHomeReview = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return(
    <div>
      <div className={'grid grid-cols-1 lg:grid-cols-2 lg:gap-16 p-16'}>
        <div className={'transition ease-in-out hover:scale-y-110 hover:scale-x-125 lg:-translate-x-12 w-full h-auto'}>
          <Image src={rivew}
            preview={false} />
        </div>
        <div className={'lg:-translate-x-24'}>
          <div className={'absolute justify-self-center lg:ml-10 text-3xl lg:text-8xl font-extrabold text-blue-950 text-opacity-10 md:ml-60 md:mt-6 lg:mt-0 md:text-5xl'}>
            REVIEW
          </div>
          <h1 className={'pt-1 lg:pt-6 text-2xl lg:text-5xl font-bold text-blue-950 md:ml-44 md:pt-6 md:text-4xl lg:ml-0'}>
            What Customers Say
          </h1>
          <p className={'pt-6 text-sm lg:text-xl text-justify md:text-2xl md:w-fit'}>
            Customer satisfaction is at the heart of everything we do at Good Feel Cow Mate.
            We value and are committed to the trust our customers place in us
            To deliver products and services that meet their expectations.
          </p>
          <div className={'lg:flex'}>
            <Image src={'https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D'}
              preview={false}
              height={100}
              width={100}
              className={'font-extrabold rounded-full border-yellow-500 border-b-4 border-l-4 mt-4 md:mt-6'} />
          </div>
          <div className={'pl-2 translate-y-12'}>
            <p className={'font-bold text-2xl'}>Adam Crews</p>
            <p className={'text-lg'}>Manager</p>
          </div>
          <div className={'lg:translate-x-32 translate-y-12 lg:flex flex flex-row-2 gap-2'}>
            <div><Button type={'text'} size={'large'} className={'bg-yellow-500 text-white'} icon={<LeftOutlined />}></Button></div>
            <div><Button type={'text'} size={'large'} className={'bg-yellow-500 text-white'} icon={<RightOutlined />}></Button></div>
          </div>
        </div>
      </div>
      <div className={'flex justify-center'}>
        <div className={' lg:w-3/6 w-8/12 mb-12 mt-12'}>
          <Slider {...settings}>
            {data.map(d =>(
              <div key={d}>
                <div className={'w-28'}>
                  <Image src={d.img}
                    width={100}
                    height={100}
                    preview={false}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MainHomeReview;
