import React from 'react';
import {Button, Form, Input} from 'antd';
import TextArea from 'antd/es/input/TextArea';

const onFinish = values => {
  // Form WhatsApp message with form data
  const whatsappMessage = `Full Name: ${values.name}\nPhone No.: ${values['phone number']}\nCity: ${values.city}\nEmail: ${values.email}\nMessage: ${values.message}`;

  // Encode message for URL
  const encodedMessage = encodeURIComponent(whatsappMessage);

  // Construct WhatsApp link with the encoded message
  const whatsappLink = `https://wa.me/9822276721?text=${encodedMessage}`;

  // Open WhatsApp link
  window.open(whatsappLink, '_blank');
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const ContactForm = () => {
  return (
    <div className={'flex justify-center'}>
      <div className={'w-56  lg:mt-16  lg:ml-24 md:mt-20 mt-12 md:w-96'}>
        <Form
          name={'basic'}
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          style={{maxWidth: 500}}
          initialValues={{remember: true}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete={'off'}
        >
          <Form.Item
            label={'Full Name'}
            name={'name'}
            rules={[{required: true, message: 'Please input your full name!'}]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={'Phone No.'}
            name={'phone number'}
            rules={[{required: true, message: 'Please input your phone number!'}]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={'City'}
            name={'city'}
            rules={[{required: true, message: 'Please input your city!'}]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={'Email'}
            name={'email'}
            rules={[{required: true, message: 'Please input your email!'}]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={'Message'}
            name={'message'}
            rules={[{required: true, message: 'Please input your message!'}]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item wrapperCol={{offset: 8, span: 16}}>
            <Button type={'primary'} htmlType={'submit'} style={{backgroundColor: 'blue'}}>Submit</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
