import React from 'react';
import {MainHomeAbout} from './screenComponent/MainHomeAbout';
import {MainHomeProduct} from './screenComponent/MainHomeProduct';
import {MainHomeReview} from './screenComponent/MainHomeReview';
import {AntdCarousel} from './screenComponent/AntdCarousel';
import {MainFooter} from './screenComponent/MainFooter';

export const Home = () => {
  return (
    <>
      <AntdCarousel />
      <MainHomeAbout />
      <MainHomeProduct />
      <MainHomeReview />
      <MainFooter />
    </>
  );
};
