import React from 'react';

const ContactMap = () => {

  return (
    <>
      <div className={'w-80 ml-10 mb-4 pt-2 lg:p-12 lg:pt-16 md:w-2/3 md:ml-36 lg:ml-16 md:mb-12 lg:mb-0 md:pt-14'}>

        <div className={'relative lg:w-full pb-96'}>

          <iframe
            src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7641.23693417201!2d74.33497263053044!3d16.745878224634104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc103ee4d63eb85%3A0x73f78c9e241dd2bf!2z8J2QhvCdkI7wnZCO8J2QgyDwnZCF8J2QhPCdkITwnZCLIPCdkILwnZCO8J2QliDwnZCM8J2QgPCdkJPwnZCS!5e0!3m2!1sen!2sin!4v1708081775115!5m2!1sen!2sin'}
            title={'Google Map'}
            width={'100%'}
            height={'100%'}
            style={{position: 'absolute', top: 0, left: 0, border: 0}}
            allowFullScreen
            loading={'lazy'}
            referrerPolicy={'no-referrer-when-downgrade'}
          ></iframe>

        </div>

      </div>
    </>
  );
};

export default ContactMap;
