import React from 'react';
import {ContactForm} from './ContactForm';
import {ContactMap} from './ContactMap';
import {HomeOutlined, RightOutlined} from '@ant-design/icons';
import photo17 from '../../../Assets/Images/photo17.jpg';
import {MainFooter} from '../screenComponent/MainFooter';

const ContactUs = () => {

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${photo17})`,
          height: '50vh',
          width: 'fit',
          backgroundSize: 'cover',
          backgroundBlendMode: 'hard-light',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        className={'bg-local lg:bg-fixed '}>
        <h1 className={'text-6xl text-white font-extrabold py-8 px-4 md:py-16 md:px-32'}>Contact Us</h1>
        <div
          className={'absolute -mb-36 w-48 h-16 bg-white text-black text-xl flex items-center left-44 bottom-80 lg:left-3/4 lg:bottom-80 border rounded 2xl:bottom-96 md:mb-56 md:ml-80 lg:ml-0 lg:mb-0'}>
          <div><HomeOutlined className={'text-xl ml-2'} />HOME</div>
          <div><RightOutlined className={'text-xl ml-1'} />Contact</div>
        </div>
      </div>

      <div className={'flex justify-center mt-4 '}>
        <div
          className={'absolute justify-self-center text-5xl lg:text-8xl font-extrabold text-black text-opacity-15 md:text-6xl'}>
            Contact Us
        </div>
        <h1 className={'pt-2 lg:pt-4 text-2xl lg:text-7xl font-bold text-black md:text-4xl'}>
            To Keep in Touch
        </h1>
      </div>

      <div className={'lg:flex lg:justify-around 2xl:justify-evenly'}>
        <ContactForm />
        <ContactMap />
      </div>

      <MainFooter />
    </>
  );
};

export default ContactUs;
