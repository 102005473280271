import React from 'react';
import {Card, Image} from 'antd';
import photo10 from '../../../../Assets/Images/photo10.jpeg';
import REGULAR from '../../../../Assets/Images/REGULAR.png';
import coww6 from '../../../../Assets/Images/coww 6.jpg';
import coww9 from '../../../../Assets/Images/coww9.jpg';
import mat2 from '../../../../Assets/Images/mat 2.png';
import mat3 from '../../../../Assets/Images/mat 3.png';
import mat4 from '../../../../Assets/Images/mat 4.png';
import coww3 from '../../../../Assets/Images/coww 3.jpg';

const items = [
  {
    id:1,
    src:photo10,
    data:REGULAR,
    title:'40 Kg REGULAR MAT' +
        'Size 6.5x4 ft Thickness 17mm to 18mm',
  },
  {
    id:2,
    src:coww6,
    data:mat2,
    title:'40 Kg INTER-LOCKING MAT' +
        'Size 6.5x4 ft Thickness 17mm to 18mm',

  },
  {
    id:3,
    src:coww9,
    data:mat3,
    title:'32 Kg REGULAR MAT' +
        'Size 6.5x4 ft Thickness 15mm to 16mm',
  },
  {
    id:4,
    src:coww3,
    data:mat4,
    title:'32 Kg INTER-LOCKING MAT' +
        'Size 6.5x4 ft Thickness 15mm to 16mm',
  },
];

const MainHomeProduct = () => {
  return(
    <div className={'mx-auto py-8 px-12 lg:px-3 bg-gray-100 lg:-translate-x-4 mt-20'}>
      <div className={'text-center'}>
        <div className={'absolute flex lg:left-1/3 text-5xl text-center lg:text-8xl font-extrabold text-blue-950 text-opacity-10 md:ml-56 lg:-ml-10 ml-4'}>PRODUCTS</div>
        <h1 className={'lg:text-6xl text-3xl lg:pt-6 pt-1 font-bold text-blue-950 md:text-4xl'}>What We Offer</h1>
        <p className={'pt-6 w-fit lg:w-5/6 lg:ml-20'}>
          Our range of cow mats has been carefully crafted to provide the perfect blend of comfort,
          Durability and efficiency. From anti-slip mats to provide stability And preventing injuries,
          soft and supportive mats for optimal comfort, we have a A variety of ranges to meet the unique
          needs of dairy farmers
        </p>
      </div>
      <div className={'grid grid-cols-1 lg:grid-cols-4 justify-items-center pt-6 lg:pl-10 md:grid-cols-2'}>
        {items.map(({id, src, data, title}) => (
          <div key={id} className={'py-4'}>
            <Card
              hoverable
              className={'max-w-sm'}
              style={{width:300, height:450}}
              cover={<Image alt={'example'} src={src}  preview={false} />}
            >
              <img alt={'Farm'}
                src={data}
                className={'absolute top-60 right-24  h-[110px] w-[110px] !rounded-full object-cover object-center'+
                    'transition duration-150 ease-in-out hover:scale-x-0 scale-y-75'} />
              <h1 className={'pt-8'}>
                {title}
              </h1>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainHomeProduct;
