import React from 'react';
import {Carousel, Image} from 'antd';
import cow5 from '../../../../Assets/Images/cow5.jpg';
import cow6 from '../../../../Assets/Images/cow6.jpeg';

const AntdCarousel = () => {
  return(
    <>
      <Carousel autoplay className={'max-w-full h-auto'}>
        <div>
          <Image
            src={cow5}
            preview={false}
          />
        </div>
        <div className={'max-w-full h-auto'}>
          <Image
            src={cow6}
            preview={false}
          />
        </div>
      </Carousel>
    </>
  );
};

export default AntdCarousel;
