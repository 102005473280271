import React from 'react';
import {HomeOutlined, RightOutlined} from '@ant-design/icons';
import {Card, Image} from 'antd';
import MainFooter from '../screenComponent/MainFooter/MainFooter';
import REGULAR from '../../../Assets/Images/REGULAR.png';
import mat2 from '../../../Assets/Images/mat 2.png';
import mat3 from '../../../Assets/Images/mat 3.png';
import mat4 from '../../../Assets/Images/mat 4.png';
import photo16 from '../../../Assets/Images/photo16.png';

const Products = () => {
  return(
    <>
      <div
        style={{
          backgroundImage: `url(${photo16})`,
          height: '50vh',
          width: 'fit',
          backgroundSize: 'cover',
          backgroundBlendMode: 'hard-light',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        className={'bg-local lg:bg-fixed'}>
        <h1 className={'text-6xl text-white font-extrabold py-8 px-4 md:py-16 md:px-32'}>Our Shop</h1>
        <div
          className={'absolute -mb-36 w-60 h-16 bg-white text-black text-xl flex items-center left-36 bottom-80 lg:left-3/4 lg:bottom-80 border rounded 2xl:bottom-96 md:ml-96 lg:mb-0 md:mb-56 lg:ml-0'}>
          <HomeOutlined className={'text-xl ml-2.5 pb-2 pr-1.5'} /> HOME
          <RightOutlined className={'text-xl ml-1 pb-2 pr-1'} /> PRODUCTS
        </div>
      </div>
      <div className={'flex justify-center m-6 mb-24 '}>
        <div
          className={'absolute justify-self-center text-5xl lg:text-9xl font-extrabold text-black text-opacity-5'}>
                    Our Shop
        </div>
        <h1 className={'pt-2 lg:pt-8 text-2xl lg:text-6xl font-bold text-black'}>
                    Products
        </h1>
      </div>

      <div className={'grid grid-cols-1 gap-1 lg:grid-cols-2 text-center  m-6 lg:pl-28 md:grid md:grid-cols-2'}>
        <div className={'lg:w-3/4 lg:pb-9'}>
          <Card title={'Regular Mat 32Kg'} bordered={false}>
            <Image src={REGULAR} />
            <p className={'pt-8'}>Introducing our reliable and versatile 40 kg regular mats designed to complete.
                                 these mats They are ideal  6.5 feet by 4 feet and  With dimensions of 17 mm to 18 mm
                                 thickness,Made from high-quality rubber or foam material, chosen for its durability,
                                 resilience, and comfort for the cows.</p>
          </Card>
        </div>
        <div className={'lg:w-3/4 lg:pb-9'}>
          <Card title={'Interlocking Mat 40Kg'} bordered={false}>
            <Image src={mat2} />
            <p className={'pt-8'}>Welcome to our premium line of 40 kg interlocking mats,where safety our
                                 mats feature a sturdy interlocking design for easy installation and secure,
                                 With dimensions of 6.5 feet by 4 feet and thicknesses ranging from 17 mm to
                                 18 mm These mats provide extensive coverage.</p>
          </Card>
        </div>
        <div className={'lg:w-3/4 lg:pb-9'}>
          <Card title={'Regular Mat 32kg'} bordered={false}>
            <Image src={mat3} />
            <p className={'pt-8'}>Introducing our reliable and versatile 32 kg designed for regular mats A
                                 strong one of these mats To measure the dimensions 6.5 feet by 4 feet and
                                 Thickness 15 mm to 16 Made from high-quality rubber or foam material chosen
                                 for its durability resilience and comfort for the cows.</p>
          </Card>
        </div>
        <div className={'lg:w-3/4 lg:pb-9'}>
          <Card title={'Interlocking Mat 32Kg'} bordered={false}>
            <Image src={mat4} />
            <p className={'pt-10'}>Welcome to our premium line of 32 kg interlocking mats, where safety,Manufactured,
                                  our mats have a strong interlocking design for easy installation and a safe, These mats
                                  with dimensions of 6.5 feet by 4 feet and thickness ranging from 15 mm to 16 mm provide
                                  Bring wide coverage.</p>
          </Card>
        </div>
      </div>
      <div className={'lg:flex lg:justify-around lg:pb-10 lg:pt-4 bg-fuchsia-250 pl-20 lg:pl-0 md:flex md:justify-around md:pb-10 md:pt-4 md:pl-0 '}>
        <div className={'pt-4 pl-4 lg:pl-0 lg:pt-1 md:pl-0 md:pt-1'}>
          <h1 className={'text-orange-500 lg:text-5xl text-3xl pl-24 font-bold'}>3</h1>
          <h1 className={'font-bold pl-2 text-2xl'}>YEAR IN INDUSTRY</h1>
        </div>
        <div className={'pt-6 lg:pt-1 pl-4 lg:pl-0 md:pt-1'}>
          <h1 className={'text-orange-500 lg:text-5xl  text-3xl font-bold pl-12 lg:pl-2 md:pl-3'}>50000 +</h1>
          <h1 className={'font-bold lg:pl-4 pl-8 text-2xl md:pl-0'}>MATS SELLED</h1>
        </div>
        <div className={'pt-6 lg:pt-0 pb-4 lg:pb-2 pl-4 lg:pl-0 md:pt-0 md:pb-2 md:pl-0'}>
          <h1 className={'text-orange-500 lg:text-5xl text-3xl font-bold pl-20 lg:pl-16 md:pl-20'}>50 +</h1>
          <h1 className={'font-bold pl-6 text-xl'}> DEALERS OVERALL </h1>
          <p className={'pl-8 text-xl font-bold'}>MAHARASHTRA</p>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default Products;
