import React from 'react';
import {Button, Card, Image} from 'antd';
import new1 from '../../../../Assets/Images/new 1.png';
import coww2 from '../../../../Assets/Images/coww2.jpg';
import {Link} from 'react-router-dom';

const MainHomeAbout = () => {
  return(
    <div className={'grid grid-cols-1 lg:grid-cols-2 p-4 lg:py-24 lg:px-14 md:m-12 lg:m-12'}>
      <div className={'py-6 '}>
        <div className={'absolute flex text-5xl lg:text-8xl font-extrabold text-blue-950 md:text-9xl text-opacity-5 '}>ABOUT</div>
        <h1 className={'lg:text-5xl text-3xl text-blue-950 mb-4 font-bold md:text-6xl'}>
            Our Mats and Learn <br />About History
        </h1>
        <div className={'lg:text-2xl mt-12 text-justify'}>
          <p className={'mb-4 text-y-600'}>
              At Good Feel Cow Mate, we believe in enhancing the comfort and well-being of our bovine friends.
              With over six years of dedicated service in the industry, we specialize in manufacturing premium quality cow mats
              are that ensure utmost comfort for your cattle.
          </p>
          <p className={'mb-8 text-y-600 '}>
              Our mission is simple:To offer premium quality cow mats that enhance the comfort and health of our bovine companions
              prefer. We understand the important role comfort plays in cattle productivity and overall well-being.
              That why we constantly strive to deliver products that exceed expectations.
          </p>
        </div>
        <div>
          <Link to={'/about'}><Button type={'default'} className={'transition text-white ease-in transform delay-150 bg-yellow-500 hover:text-white hover:-translate-y-1 hover:scale-110 hover:bg-gray-600 hover:border-none duration-500 rounded-none text-1xl  uppercase h-12 w-40 font-bold'}>Read More</Button></Link>
        </div>
      </div>
      <div className={'transition duration-700 ease-in hover:scale-x-90 justify-self-auto mb-64 md:ml-20 md:pb-24 lg:pb-0 lg:ml-0'}>
        <div className={'translate-y-20 translate-x-40 lg:translate-x-80 lg:h-28 h-14 w-52 lg:w-80 mt-24 md:w-80 md:ml-16 lg:ml-0'}>
          <Image
            src= {coww2}
            preview={false}
            className={'rounded-xl'}>
          </Image>
        </div>
        <div className={'relative lg:translate-x-28 lg:-translate-y-16 lg:h-24 h-14 w-52 lg:w-80  md:w-80'}>
          <Image
            src={new1}
            preview={false}
            className={'rounded-xl'}>
          </Image>
        </div>
        <div className={'absolute translate-x-32 lg:translate-x-80 -translate-y-32 lg:-translate-y-64 text-center'}>
          <Card bordered={false} className={'bg-yellow-500 uppercase font-bold lg:p-2 rounded-2xl md:ml-20 lg:ml-0'}>
            <p className={'lg:text-xl'}>Best quality</p>
            <p className={'lg:text-xl'}>From</p>
            <p className={'text-white lg:text-3xl'}>2018</p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MainHomeAbout;
