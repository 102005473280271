import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import './NavBar.css';
import {HamburgetMenuClose, HamburgetMenuOpen} from './Icons';
import {MailOutlined, PhoneOutlined} from '@ant-design/icons';

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <div className={'h-16 pt-2 lg:pt-0 text-end lg:h-9 lg:flex lg:justify-end sticky bg-slate-900'}>
        <text className={'pr-3 pt-2 w-68 text-white'}>
          <PhoneOutlined className={'pt-1.5 pr-2'} />
          <text className={'lg:pr-3'}>7744001589 | 9822276721 </text>
        </text>
        <text className={'lg:pr-3 lg:pt-2 lg:w-68 text-white'}>
          <MailOutlined className={'absolute pt-1.5'} />
          <text className={'pl-5 pr-3'}>contact@goodfeelcowmats.com</text>
        </text>
      </div>
      <nav className={'navbar'}>
        <div className={'nav-container '}>
          <NavLink exact to={'/'} className={'nav-logo'}>
            <img src={require('../Assets/Images/Good Feel - PNG.png')} style={{width: 120, height: 54, marginLeft:18}}  alt={''} />
          </NavLink>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className={'nav-item'}>
              <NavLink
                exact
                to={'/'}
                activeClassName={'active'}
                className={'nav-links'}
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className={'nav-item'}>
              <NavLink
                exact
                to={'/about'}
                activeClassName={'active'}
                className={'nav-links'}
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className={'nav-item'}>
              <NavLink
                exact
                to={'/gallery'}
                activeClassName={'active'}
                className={'nav-links'}
                onClick={handleClick}
              >
                Gallery
              </NavLink>
            </li>
            <li className={'nav-item'}>
              <NavLink
                exact
                to={'/products'}
                activeClassName={'active'}
                className={'nav-links'}
                onClick={handleClick}
              >
                Products
              </NavLink>
            </li>
            <li className={'nav-item'}>
              <NavLink
                exact
                to={'/contact'}
                activeClassName={'active'}
                className={'nav-links'}
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
          <div className={'nav-icon'} onClick={handleClick}>
            {click ? (
              <span className={'icon'}>
                <HamburgetMenuClose />
              </span>
            ) : (
              <span className={'icon'}>
                <HamburgetMenuOpen />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
