import React from 'react';
import {Image} from 'antd';
import {HomeOutlined, RightOutlined} from '@ant-design/icons';
import photo17 from '../../Assets/Images/photo17.jpg';
import {MainFooter} from './screenComponent/MainFooter';
import photo1 from '../../Assets/Images/photo1.jpeg';
import photo2 from '../../Assets/Images/photo2.jpeg';
import photo3 from '../../Assets/Images/photo3.jpeg';
import photo4 from '../../Assets/Images/photo4.jpeg';
import photo5 from '../../Assets/Images/photo5.jpeg';
import photo6 from '../../Assets/Images/photo6.jpeg';
import photo7 from '../../Assets/Images/photo7.jpeg';
import photo8 from '../../Assets/Images/photo8.jpeg';
import photo9 from '../../Assets/Images/photo9.jpeg';
import photo10 from '../../Assets/Images/photo10.jpeg';
import photo11 from '../../Assets/Images/photo11.jpeg';
import photo12 from '../../Assets/Images/photo12.jpeg';
import photo13 from '../../Assets/Images/photo13.jpeg';
import photo14 from '../../Assets/Images/photo14.jpeg';
import photo15 from '../../Assets/Images/photo15.jpeg';

export const Gallery = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${photo17})`,
          height: '50vh',
          width: 'fit',
          backgroundSize: 'cover',
          backgroundBlendMode: 'hard-light',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        className={'bg-local lg:bg-fixed'}
      >
        <h1 className={'text-6xl text-white font-extrabold py-8 px-4 md:py-16 md:px-32'}>Gallery</h1>
        <div
          className={'absolute -mb-36 w-48 h-16 bg-white text-black text-xl flex items-center left-44 bottom-80 lg:left-3/4 lg:bottom-80 border rounded 2xl:bottom-96 md:left-3/4 md:mb-56 lg:mb-0'}>
          <div><HomeOutlined className={'text-xl ml-2'} /> HOME</div>
          <div><RightOutlined className={'text-xl ml-1'} />Gallery</div>
        </div>
      </div>
      <div className={'lg:p-8 p-2 lg:ml-24 lg:mr-24'}>
        <h1 className={'text-3xl text-center lg:text-left mt-4 lg:text-5xl font-extrabold'}>Photos</h1>
        <div className={'p-6 lg:p-8 lg:grid lg:grid-cols-3 grid-cols-1 lg:space-y-2 space-y-3 md:grid md:grid-cols-2 ml-4 lg:ml-0  '}>
          <Image.PreviewGroup>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo1}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo2}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo3}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo4}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo5}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo6}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo7}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo8}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo9}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo10}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo11}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo12}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo13}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo14}
              />
            </div>
            <div className={'justify-self-center w-80 h-80'}>
              <Image
                src={photo15}
              />
            </div>
          </Image.PreviewGroup>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};
