import './App.css';
import NavBar from './components/NavBar';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {Home} from './components/Pages/Home';
import {About} from './components/Pages/About';
import {Gallery} from './components/Pages/Gallery';
import {ContactUs} from './components/Pages/ContactUs';
import {Products} from './components/Pages/Products';

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <div>
          <Routes>
            <Route path={'/'} element={<Home />} />
            <Route path={'/about'} element={<About />} />
            <Route path={'/gallery'} element={<Gallery />} />
            <Route path={'/products'} element={<Products />} />
            <Route path={'/contact'} element={<ContactUs />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
